import React from 'react';
import ServiceItem from '../ServiceItem/ServiceItem';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// Interface
import { Service } from '../../../../src/api/types/vendor_services';
interface serviceProps {
  data: Service[];
  servicesAddRemove: Function;
  serviceIDS: [];
}
const ServiceList: React.FC<serviceProps> = ({
  data,
  servicesAddRemove,
  serviceIDS,
}) => {
  const services: Service[] = data;
  return (
    <Box sx={{ width: '100%', mt: { xs: 10, md: 0 } }}>
      {[...Array(3)].map((_, rowIndex) => (
        <Grid container spacing={2} key={rowIndex} sx={{ mt: 2 }}>
          {services
            .slice(rowIndex * 2, rowIndex * 2 + 2)
            .map((service, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <ServiceItem
                  serviceIDS={serviceIDS}
                  data={service}
                  servicesAddRemove={servicesAddRemove}
                />
              </Grid>
            ))}
        </Grid>
      ))}
    </Box>
  );
};
export default ServiceList;
