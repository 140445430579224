import { API_URLS } from '../core/apis/apis';
import useAxiosPrivate from '../core/hooks/useAxiosPrivate';
import useAxios from '../core/hooks/useAxios';

const AuthService = () => {
  const axiosPrivate = useAxiosPrivate();
  const axios = useAxios();
  // Define and return authentication service functions
  const services = () => {
    return {
      sendOtp: async (data: any) => {
        return await axios.post(`${API_URLS.auth.send_customer_otp}`, data);
      },
      register: async (data: any) => {
        return await axios.post(`${API_URLS.auth.customer_registration}`, data);
      },
      login: async (data: any) => {
        return await axios.post(`${API_URLS.auth.customer_login}`, data);
      },
    };
  };
  return services;
};

export default AuthService;
