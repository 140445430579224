import React from 'react';
import { Breadcrumbs, Link, Typography, Box } from '@mui/material';
import './Breadcrumb.css';

type BreadcrumbProps = {
  currentPage: string;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ currentPage }) => {
  return (
    <nav aria-label="Breadcrumb" className="breadcrumb-container">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/07dfbb0e12a932e0cd646173426063c2a6c7f78455654678416df566ba09aa54?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13"
        alt=""
        className="breadcrumb-image"
      />
      <Box className="breadcrumb-content">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Typography color="textPrimary">{currentPage}</Typography>
        </Breadcrumbs>
      </Box>
    </nav>
  );
};

export default Breadcrumb;
