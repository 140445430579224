import React from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  Card,
  CardMedia,
  CardContent,
  Grid,
} from '@mui/material';
import './AppointmentDetails.css';
import { images } from '../../../Assets/images';
import { icons } from '../../../Assets/icons';

type AppointmentDetailsProps = {
  title: string;
  date: string;
  location: string;
  service: string;
  processTime: string;
  price: string;
  bookingRef: string;
};

const AppointmentDetails: React.FC<AppointmentDetailsProps> = ({
  title,
  date,
  location,
  service,
  processTime,
  price,
  bookingRef,
}) => {
  return (
    <Card className="appointment-card">
      <CardMedia
        component="img"
        image={images.candvBeauty}
        alt={title}
        className="appointment-image"
      />
      <CardContent className="appointment-content">
        <Typography
          variant="h4"
          className="appointment-title"
          sx={{ marginTop: '30px' }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          className="appointment-date"
          sx={{ marginTop: '10px' }}
        >
          {date}
        </Typography>
        <Box
          className="status-container"
          sx={{ marginTop: '20px', marginBottom: '20px' }}
        >
          <img
            loading="lazy"
            src={icons.rightTick}
            alt="Confirmed"
            className="status-icon"
          />
          <Typography variant="body1">Confirmed</Typography>
        </Box>
        <Divider className="divider" />
        <Box className="getting-there-container">
          <img
            loading="lazy"
            src={icons.upcomingCalender}
            alt="Getting There"
            className="getting-there-icon"
          />
          <Box sx={{ marginTop: '10px', marginBottom: '10px' }}>
            <Typography variant="h6">Add to Calender</Typography>
            <Typography variant="body2">Set Yourself a Reminder</Typography>
          </Box>
        </Box>
        <Divider className="divider" />
        <Box className="getting-there-container">
          <img
            loading="lazy"
            src={icons.upcomingLocation}
            alt="Getting There"
            className="getting-there-icon"
          />
          <Box sx={{ marginTop: '10px', marginBottom: '10px' }}>
            <Typography variant="h6">Getting There</Typography>
            <Typography variant="body2">{location}</Typography>
          </Box>
        </Box>
        <Divider className="divider" />
        <Box
          className="manage-appointment-container"
          sx={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <img
            loading="lazy"
            src={icons.upcomingManage}
            alt="Manage Appointment"
            className="manage-appointment-icon"
          />
          <Box>
            <Typography variant="h6">Manage Appointment</Typography>
            <Typography variant="body2">Reschedule or Cancel</Typography>
          </Box>
        </Box>
        <Divider className="divider" />
        <Box
          className="venue-details-container"
          sx={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <img
            loading="lazy"
            src={icons.upcomingVenue}
            alt="Venue Details"
            className="venue-details-icon"
          />
          <Box>
            <Typography variant="h6">Venue Details</Typography>
            <Typography variant="body2">The Fluxus House</Typography>
          </Box>
        </Box>
        <Box className="overview-container">
          <Typography variant="h5">Overview</Typography>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={7}>
              <Typography variant="body1">{service}</Typography>
              <Typography variant="body2">
                Process Time: {processTime}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                variant="h5"
                sx={{ textAlign: 'right' }}
                className="price"
              >
                {price}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h6">Total</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                variant="h5"
                sx={{ textAlign: 'right' }}
                className="price"
              >
                {price}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Typography
          variant="h6"
          className="cancellation-policy"
          sx={{ marginTop: '10px' }}
        >
          Cancellation Policy
        </Typography>
        <Typography variant="body2" sx={{ marginTop: '10px' }}>
          Please avoid cancelling within{' '}
          <span className="highlight">30 minutes</span> of your appointment
          time.
        </Typography>
        <Typography variant="h6" sx={{ marginTop: '10px' }}>
          Booking Reference: {bookingRef}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AppointmentDetails;
