import {
  getVendorDetails,
  getServiceList,
  createAppointment,
  checkAppointmentAvaibility,
  getProfessional,
  getAppointmentList,
  getVendorServices,
  getAppointmentSlots,
  addRemoveFavVendor,
  getFavList,
  getAppointmentListVendor
} from '../../src/api/shopAPI';
import { vendorDetailsResponse } from '../../src/api/types/shop';
import {
  servicesListAPIResponse,
  serviceListReuestIntertface,
} from '../../src/api/types/services';
import {
  appointmentCreateRequestInterface,
  appointmentCreateResponse,
  checkAppointmentAviabilityRequests,
} from '../../src/api/types/appointment';
import {
  proffestionalAPIResponse,
  proffestionalRequestInterface,
} from '../../src/api/types/proffestional';
import {
  appointmentListAPIRequest,
  appointmentListAPIResponse,
} from '../../src/api/types/appointment_list';
import {
  vendorServicesReqInterface,
  vendorServicesResInterface,
} from '../../src/api/types/vendor_services';
import {
  appointmentSlotRequestInterface,
  appointmentAPIResponse,
} from '../../src/api/types/appointment_slots';
import { ApiResponse } from '../../src/api/types/common';
export const ShopAPI = () => {
  // Vendor Details
  const getVendorDetailsAPICall = async (
    vendor_detail_id: number,
  ): Promise<vendorDetailsResponse> => {
    try {
      const response: vendorDetailsResponse =
        await getVendorDetails(vendor_detail_id);

      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as vendorDetailsResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as vendorDetailsResponse;
    }
  };
  // Service List
  const getVendorServiceList = async (
    requestData: serviceListReuestIntertface,
  ): Promise<servicesListAPIResponse> => {
    try {
      const response: servicesListAPIResponse =
        await getServiceList(requestData);

      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as servicesListAPIResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as servicesListAPIResponse;
    }
  };
  // Appointment Create
  const createAppointmentAPICall = async (
    requestData: appointmentCreateRequestInterface,
  ): Promise<appointmentCreateResponse> => {
    try {
      const response: appointmentCreateResponse =
        await createAppointment(requestData);

      return {
        data: '',
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as appointmentCreateResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as appointmentCreateResponse;
    }
  };
  // Appointment Check Avaibility
  const checkAppointmentAvaibilityAPICall = async (
    requestData: checkAppointmentAviabilityRequests,
  ): Promise<appointmentCreateResponse> => {
    try {
      const response: appointmentCreateResponse =
        await checkAppointmentAvaibility(requestData);

      return {
        data: '',
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as appointmentCreateResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as appointmentCreateResponse;
    }
  };
  // Get Professional
  const getProfessionalAPI = async (
    requestData: proffestionalRequestInterface,
  ): Promise<proffestionalAPIResponse> => {
    try {
      const response: proffestionalAPIResponse =
        await getProfessional(requestData);

      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as proffestionalAPIResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as proffestionalAPIResponse;
    }
  };
  // Get Appointment List
  const getAppointmentListAPI = async (
    requestData: appointmentListAPIRequest,
  ): Promise<appointmentListAPIResponse> => {
    try {
      const response: appointmentListAPIResponse =
        await getAppointmentList(requestData);

      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as appointmentListAPIResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as appointmentListAPIResponse;
    }
  };
  // Get Vendor Services
  const getVendorServicesAPI = async (
    requestData: vendorServicesReqInterface,
  ): Promise<vendorServicesResInterface> => {
    try {
      const response: vendorServicesResInterface =
        await getVendorServices(requestData);

      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as vendorServicesResInterface;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as vendorServicesResInterface;
    }
  };
  // Get Appointment Slots
  const getAppoinmentSlotsAPICall = async (
    requestData: appointmentSlotRequestInterface,
  ): Promise<appointmentAPIResponse> => {
    try {
      const response: appointmentAPIResponse =
        await getAppointmentSlots(requestData);

      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as appointmentAPIResponse;
    } catch (err: any) {
      return {
        data: null,
        message: err.toString(),
        status_code: 500,
        success: false,
      } as appointmentAPIResponse;
    }
  };
  // Add Remove Fav
  const addRemoveFavVendorAPICall = async (
    vendor_detail_id: number,
  ): Promise<ApiResponse> => {
    try {
      const response: ApiResponse =
        await addRemoveFavVendor(vendor_detail_id);
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as ApiResponse;
    } catch (err: any) {
      return {
        message: err.toString(),
        status_code: 500,
        success: false,
      } as ApiResponse;
    }
  };
  // Get Fav Vendors
  const getFavVendorListAPICall = async (): Promise<ApiResponse> => {
    try {
      const response: ApiResponse =
        await getFavList();
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as ApiResponse;
    } catch (err: any) {
      return {
        message: err.toString(),
        status_code: 500,
        success: false,
      } as ApiResponse;
    }
  };
  // Get Appointment List
  const getAppointmentListVendorAPICall = async (customer_id: number): Promise<ApiResponse> => {
    try {
      const response: ApiResponse =
        await getAppointmentListVendor(customer_id);
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as ApiResponse;
    } catch (err: any) {
      return {
        message: err.toString(),
        status_code: 500,
        success: false,
      } as ApiResponse;
    }
  };
  return {
    getVendorDetailsAPICall,
    getVendorServiceList,
    createAppointmentAPICall,
    checkAppointmentAvaibilityAPICall,
    getProfessionalAPI,
    getAppointmentListAPI,
    getVendorServicesAPI,
    getAppoinmentSlotsAPICall,
    addRemoveFavVendorAPICall,
    getFavVendorListAPICall,
    getAppointmentListVendorAPICall
  };
};
