import React from 'react';
import AppointmentCard from '../AppointmentCard/AppointmentCard';
import { Box, Typography, Chip } from '@mui/material';
import './AppointmentSection.css';

type AppointmentSectionProps = {
  title: string;
  count: number;
  data: any
  isPast?: boolean;
};

const AppointmentSection: React.FC<AppointmentSectionProps> = ({
  title,
  count,
  data,
  isPast = false,
}) => {
  console.log(title, "title");
  console.log(count, "count");
  console.log(data, "data");
  console.log(isPast, "isPast");

  return (
    <section>
      <Box display="flex" alignItems="center" gap={2.5} mt={4}>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontSize: '26px', fontWeight: 'bold' }}
          className="title"
        >
          {title}
        </Typography>
        <Chip
          label={count}
          className={`count-chip ${isPast ? 'past' : 'upcoming'}`}
        />
      </Box>
      {data.map((appointment: any, index: number) => (
        <AppointmentCard
          key={index}
          title={appointment.business}
          date={appointment.appointment_date}
          price={appointment.total_amount}
          itemCount={appointment.total_services}
          isPast={isPast}
        />
      ))}
    </section>
  );
};

export default AppointmentSection;
