import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Container,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
} from '@mui/material';
import './AddressDetails.css';
import './CustomButton.css';
import './InputField.css';
import {
  ApiResponse,
  CityList,
  StateList,
} from '../../../../src/api/types/common';
import { CountryList } from '../../../../src/api/types/common';
import toast from 'react-hot-toast';
import { icons } from '../../../Assets/icons';
import { CommonAPI } from '../../../../src/hooks/common';
import { CustomerAPI } from '../../../../src/hooks/customer';
import { CustomerAddressReqInterface } from '../../../../src/api/types/customer';

interface AddressDetailsProps {
  onCancel: () => void;
  onContinue: () => void;
  addressData: any;
}

const AddressDetails: React.FC<AddressDetailsProps> = ({
  onCancel,
  onContinue,
  addressData,
}) => {
  const { getCountryListAPICall, getStateListAPICall, getCityListAPICall } =
    CommonAPI();
  const { addCustomerAddressAPICall } = CustomerAPI();
  const [address, setAddress] = useState(addressData.address_line_1);
  const [app, setApp] = useState(addressData.address_line_2);
  const [city, setCity] = useState(addressData.city_id || '');
  const [state, setState] = useState(addressData.state_id || '');
  const [country, setCountry] = useState(addressData.country_id || '');
  const [postcode, setPostcode] = useState(addressData.postal_code);

  const [cityList, setCityList] = useState<CityList[]>([]);
  const [stateList, setStateList] = useState<StateList[]>([]);
  const [countryList, setCountryList] = useState<CountryList[]>([]);

  const [addressError, setAddressError] = useState('');
  const [appError, setAppError] = useState('');
  const [cityError, setCityError] = useState('');
  const [stateError, setStateError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [postcodeError, setPostcodeError] = useState('');

  useEffect(() => {
    const fetchCountryList = async () => {
      const result: ApiResponse<CountryList> = await getCountryListAPICall();
      if (result?.status_code === 200) {
        setCountryList(result.data);
      } else {
        toast.error(result?.message || 'Failed to fetch country data.');
      }
    };

    fetchCountryList();
  }, []);

  useEffect(() => {
    if (country) {
      getStateListAPICallFunc(country);
    }
  }, [country]);

  useEffect(() => {
    if (country && state) {
      getCityListAPICallFunc(country, state);
    }
  }, [country, state]);

  const getCityListAPICallFunc = async (
    country_id: number,
    state_id: number,
  ) => {
    const result: ApiResponse<CityList> = await getCityListAPICall(
      state_id,
      country_id,
    );
    if (result?.status_code === 200) {
      setCityList(result.data);
    } else {
      toast.error(result?.message || 'Failed to fetch city data.');
    }
  };

  const getStateListAPICallFunc = async (country_id: number) => {
    const result: ApiResponse<StateList> =
      await getStateListAPICall(country_id);
    if (result?.status_code === 200) {
      setStateList(result.data);
    } else {
      toast.error(result?.message || 'Failed to fetch state data.');
    }
  };

  const addCustomerAddressAPICallFunc = async (
    data: CustomerAddressReqInterface,
  ) => {
    const result: any = await addCustomerAddressAPICall(data);
    if (result === null) {
      toast.error('Failed to add customer data.');
      return;
    }
    if (result?.status_code === 200) {
      onCancel();
      toast.success('Address updated successfully');
    } else {
      toast.error(result?.message);
    }
  };

  console.log(addressData, 'addressData');

  const handleContinue = () => {
    setAddressError(address ? '' : 'Please enter your address');
    setAppError(app ? '' : 'Please enter your apartment suite');
    setCityError(city ? '' : 'Please select your city');
    setStateError(state ? '' : 'Please select your state');
    setCountryError(country ? '' : 'Please select your country');
    setPostcodeError(postcode ? '' : 'Please enter your postcode');
    // Trigger continue action if no errors are set
    if (
      !addressError &&
      !appError &&
      !cityError &&
      !stateError &&
      !countryError &&
      !postcodeError
    ) {
      let requestBody: CustomerAddressReqInterface = {
        address_type: addressData.address_type,
        address_line_1: address,
        address_line_2: app,
        country_id: country,
        state_id: state,
        city_id: city,
        postal_code: postcode,
        address_id: addressData.address_id,
      };
      addCustomerAddressAPICallFunc(requestBody);
    }
  };

  return (
    <main className="address-main">
      <Container className="address-container">
        <Box className="address-box">
          <Box className="header-box">
            <Typography variant="h4" component="h1">
              Edit Address Details
            </Typography>
            <img
              loading="lazy"
              src={icons.cancel}
              className="address-image"
              alt="Edit Address"
              onClick={onCancel}
            />
          </Box>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Address"
                  className="input-field"
                  fullWidth
                  value={address}
                  margin="normal"
                  variant="outlined"
                  error={!!addressError}
                  helperText={addressError}
                  onChange={e => setAddress(e.target.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Apt./Suite etc"
                  value={app}
                  className="input-field"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={!!appError}
                  helperText={appError}
                  onChange={e => setApp(e.target.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!countryError}
                >
                  <InputLabel>Country</InputLabel>
                  <Select
                    value={country}
                    onChange={e => {
                      setCountry(e.target.value as string);
                      getStateListAPICallFunc(parseInt(e.target.value));
                    }}
                  >
                    {countryList.map(countryItem => (
                      <MenuItem
                        key={countryItem.country_id}
                        value={countryItem.country_id}
                      >
                        {countryItem.country_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{countryError}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" error={!!stateError}>
                  <InputLabel>State</InputLabel>
                  <Select
                    value={state}
                    onChange={e => {
                      setState(e.target.value as string);
                      getCityListAPICallFunc(country, parseInt(e.target.value));
                    }}
                  >
                    {stateList.map(stateItem => (
                      <MenuItem key={stateItem.id} value={stateItem.id}>
                        {stateItem.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{stateError}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" error={!!cityError}>
                  <InputLabel>City</InputLabel>
                  <Select
                    value={city}
                    onChange={e => setCity(e.target.value as string)}
                  >
                    {cityList.map(cityItem => (
                      <MenuItem key={cityItem.id} value={cityItem.id}>
                        {cityItem.city_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{cityError}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Postcode"
                  className="input-field"
                  fullWidth
                  value={postcode}
                  margin="normal"
                  variant="outlined"
                  error={!!postcodeError}
                  helperText={postcodeError}
                  onChange={e => setPostcode(e.target.value)}
                />
              </Grid>
            </Grid>
            <Divider className="address-divider" />
            <Box className="button-box">
              <Button className="cancel" onClick={onCancel} variant="outlined">
                Cancel
              </Button>
              <Button
                className="continue"
                onClick={handleContinue}
                variant="contained"
              >
                Update Address
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </main>
  );
};

export default AddressDetails;
