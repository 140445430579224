import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import './ServiceNavigation.css';
import { icons } from '../../../Assets/icons';
interface navigationProps {
  bookPage: number;
  setBookPage: Function;
  navigationFunc: Function;
  setTeamSelectionType: Function;
  setIsSlotAvailbel: Function;
}
const ServiceNavigation: React.FC<navigationProps> = ({
  bookPage,
  setBookPage,
  navigationFunc,
  setTeamSelectionType,
  setIsSlotAvailbel,
}) => {
  const goToPageFunc = (bookPage: number) => {
    if (bookPage <= 1) {
      navigationFunc('/shop');
    } else {
      let nextPageValue = bookPage - 1;
      setBookPage(nextPageValue);
      setIsSlotAvailbel(true);
      if (nextPageValue === 2) {
        setTeamSelectionType(1);
      }
      if (nextPageValue === 3) {
        setIsSlotAvailbel(false);
      }
    }
  };
  return (
    <Box className="service-navigation">
      <Button
        onClick={() => {
          goToPageFunc(bookPage);
        }}
        style={{ lineHeight: '0px' }}
      >
        <img
          loading="lazy"
          src={icons.left}
          alt=""
          className="service-navigation__image"
        />
      </Button>
      <Box className="service-navigation__text">
        {bookPage === 1 && (
          <Typography variant="body1">
            Services &gt;{' '}
            <span className="service-navigation__highlight">
              Select Team &gt; Time &gt; Confirm
            </span>
          </Typography>
        )}
        {bookPage === 2 && (
          <Typography variant="body1">
            Services &gt; Select Team &gt;{' '}
            <span className="service-navigation__highlight">
              Time &gt; Confirm
            </span>
          </Typography>
        )}
        {bookPage === 3 && (
          <Typography variant="body1">
            Services &gt; Select Team &gt; Time &gt;{' '}
            <span className="service-navigation__highlight"> Confirm</span>
          </Typography>
        )}
        {bookPage === 4 && (
          <Typography variant="body1">
            Services &gt; Select Team &gt; Time &gt; Confirm
          </Typography>
        )}
        {bookPage === 5 && (
          <Typography variant="body1">
            Services &gt; Select Team &gt; Time &gt; Confirm
          </Typography>
        )}
      </Box>
    </Box>
  );
};
export default ServiceNavigation;
