import axios from 'axios';
// Base URL for API requests obtained from environment variables
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Export axios instance configured with the base URL
export default axios.create({
  baseURL: API_BASE_URL,
  maxRedirects: 0,
});

// Export another axios instance with the same base URL
export const axiosPrivate = axios.create({
  baseURL: API_BASE_URL,
  maxRedirects: 0,
});
