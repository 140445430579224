// SelectField.tsx
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import './SelectField.css';

interface SelectFieldProps {
  label: string;
}

const SelectField: React.FC<SelectFieldProps> = ({ label }) => {
  return (
    <div className="select-field">
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor={label.toLowerCase()}>{label}</InputLabel>
        <Select id={label.toLowerCase()} label={label} className="select-input">
          <MenuItem value="">
            <em>{label}</em>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectField;
