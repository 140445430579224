import React from 'react';
import TeamMemberType from '../TamMemberType/TeamMember';
import { Box, Typography, Grid } from '@mui/material';
import './TeamSelection.css';
import { icons } from '../../../Assets/icons';
interface TeamSelectionProps {
  selectTeam: Function;
}
const TeamSelection: React.FC<TeamSelectionProps> = ({ selectTeam }) => {
  const teamMembers = [
    {
      name: 'Any Professional',
      image: icons.users,
      description: 'For Maximum Availability',
      isSpecial: true,
      type: 1,
    },
    {
      name: 'Select Professional',
      image: icons.usersPlus,
      description: 'Per Service',
      isSpecial: true,
      type: 2,
    },
  ];
  return (
    <Grid item xs={12} md={9} className="team-selection">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Typography variant="h2" className="title">
          Select Team
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          className="team-member-grid"
        >
          {teamMembers.map((member, index) => (
            <Grid
              onClick={() => {
                selectTeam(member.type);
              }}
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
            >
              <TeamMemberType {...member} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Grid>
  );
};

export default TeamSelection;
