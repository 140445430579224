import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import CommonCookie from '../../CommonCookie';
import { useCookies } from 'react-cookie';
import { images } from '../../../../src/Assets/images';
import './Header.css'
const Header = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [cookies, , removeCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  useEffect(() => {
    setShow(false);
    let currentPage = location.pathname.toString();
    let notAllowedPages: string[] = ['/login', '/register', '/otp'];
    if (!notAllowedPages.some(page => currentPage.includes(page))) {
      setShow(true);
    }
  }, [location]);

  const handleToggle = () => {
    setIsOpen(prev => !prev);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen]);

  const logout = () => {
    setIsOpen(false);
    removeCookie('token', { path: '/' });
    navigate('/');
  };

  return (
    <>
      {show && (
        <>
          <Box
            className="mainHeader"
            sx={{
              width: '100%',
              top: 0,
              left: 0,
              backgroundColor: '#f6f6f6',
              padding: { xl: '1.25rem', md: '0.6rem 2.5rem 2.5rem 2.5rem', xs : '1.25rem'  },
            }}
          >
            <AppBar color="transparent" elevation={0}>
              <Toolbar
                className="header"
                sx={{ justifyContent: 'space-between' }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    cursor: 'pointer',
                    fontWeight: 'normal',
                    fontSize: { xs: '2rem', md: '3rem' },
                  }}
                  onClick={() => navigate('/')}
                >
                  <span className='logoMainTitle'>Glam</span>
                  <span className='logoSubTitle'
                    style={{
                      fontFamily: 'Poppins-ExtraBold,Helvetica',
                      fontWeight: 'bolder',
                    }}
                  >
                    9
                  </span>
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: { xs: 0, md: 0 },
                  }}
                >
                  

                  {!cookies.token && (
                    <>
                      <Link to="/login">
                        <Typography
                          variant="h4"
                          className='menuTitle'
                        >
                          Login
                        </Typography>
                      </Link>  |
                      <Link to="/register">
                        <Typography
                          variant="h4"
                          className='menuTitle'
                        >
                          Sign Up
                        </Typography>
                      </Link>
                    </>
                  )}
                  <Typography
                    variant="h4"
                    className='menuTitle'
                    sx={{
                      borderRadius: "60px",
                      border: "1px solid var(--Black, #231F20)",
                      padding: "13px 24px 13px 24px"
                    }}
                  >
                    For Business
                  </Typography>

                  {cookies.token ? (
                    <img
                      loading="lazy"
                      src={images.jack}
                      alt=""
                      className="profileImage"
                      onClick={handleToggle}
                    />
                  ) : (
                    <Link to="/">&nbsp;</Link>
                  )}
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
          {isOpen && (
            <Box className="userProfile">
              <Typography variant="h4" className="userName">
                Tarun
              </Typography>
              <hr />
              <Link to="/profile" className="navLink">
                Profile
              </Link>
              <Link to="/appointment" className="navLink">
                Appointment
              </Link>
              <Link to="/favorite" className="navLink">
                Favourites
              </Link>
              <hr />
              <span onClick={logout} className="logoutButton">
                Log Out
              </span>
            </Box>
          )}
          <hr className='headerDivider'></hr>
        </>
      )}
    </>
  );
};

export default Header;
