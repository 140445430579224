import React from 'react';
import ServiceCard from './ServiceCard/ServiceCard';
import TeamMember from '../team/Teammember/TeamMember';
import './professional.css';
import { Box, Typography, Grid } from '@mui/material';
// Interface
import { Service } from '../../../src/api/types/vendor_services';
import { professtionalObject } from '../../../src/api/types/proffestional';
interface professionalProps {
  data: Service[];
  getProfesstionalFunc: Function;
  vendorServiceMembers: professtionalObject[];
  selectProfesstionalFunc: Function;
}
const Professional: React.FC<professionalProps> = ({
  data,
  getProfesstionalFunc,
  vendorServiceMembers,
  selectProfesstionalFunc,
}) => {
  return (
    <Grid item xs={12} md={9} className="team-selection">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Typography variant="h2" className="title">
          Select Professional
        </Typography>
        <Typography
          variant="h5"
          component="h3"
          sx={{ mt: 2, fontWeight: 'bold', color: 'black' }}
        >
          Mark
        </Typography>
        {!vendorServiceMembers || vendorServiceMembers.length === 0 ? (
          <Grid
            container
            className="professionalContainer"
            sx={{ flexDirection: { xs: 'column', md: 'row' } }}
          >
            <Grid item xs={12} md={6}>
              {data && data.length > 0 ? (
                <>
                  {data.map((row: Service, index: number) => (
                    <ServiceCard
                      key={`service_card_key_${index}`}
                      serviceName={row.service_name}
                      processTime={row.service_time}
                      professionalName={
                        row?.selectedProfessional ?? 'Select Professional'
                      }
                      getProfesstionalFunc={getProfesstionalFunc}
                      serviceObject={row}
                    />
                  ))}
                </>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
            <Grid item xs={12} md={12}>
              {vendorServiceMembers && vendorServiceMembers.length > 0 ? (
                <Grid
                  container
                  sx={{
                    flexDirection: { xs: 'column', md: 'row' },
                    marginTop: '10px',
                  }}
                >
                  {vendorServiceMembers.map(
                    (row: professtionalObject, index: number) => (
                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={{ marginBottom: '20px', marginRight: '10px' }}
                      >
                        <TeamMember
                          selectProfesstionalFunc={selectProfesstionalFunc}
                          key={`service_team_member_key_${index}`}
                          name={`${row.first_name} ${row.last_name}`}
                          image={row.profile_image}
                          description={''}
                          isSpecial={false}
                          professtionalObject={row}
                        />
                      </Grid>
                    ),
                  )}
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Grid>
  );
};
export default Professional;
