import React from 'react';
import CouponCard from '../CoupenCard/CouponCard';
import CouponInput from '../CoupenInput/CouponInput';
import { Container, Box, Typography, Divider, Grid } from '@mui/material';
import { icons } from '../../../Assets/icons';

interface CouponData {
  title: string;
  description: string;
  code: string;
  className: string;
  isLocked?: boolean;
  unlockMessage?: string;
  amount: number;
}

interface CouponSectionProps {
  handleClose: () => void;
  onApplyCoupon: Function;
}

const coupons: CouponData[] = [
  {
    title: 'Flat 50% Off',
    description:
      'Get Flat 50% Off On Order Above ₹199, Is It Applicable On Existing Offer',
    code: 'GLAM950',
    className: 'enable',
    amount: 200,
  },
  {
    title: 'Flat 50% Off',
    description:
      'Get Flat 50% Off On Order Above ₹399, Not Applicable On Existing Offer',
    code: 'GLAM950',
    isLocked: true,
    unlockMessage: 'Add Service Worth ₹399.00 More To Unlock',
    className: 'disable',
    amount: 500,
  },
];

const CouponSection: React.FC<CouponSectionProps> = ({
  handleClose,
  onApplyCoupon,
}) => {
  // Add onApplyCoupon here
  return (
    <Box component="section" className="coupon-section">
      <Container maxWidth="lg">
        <Box className="coupon-container">
          <Grid
            className="coupenContainer"
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              className="coupenItem"
              sx={{ maxWidth: '100%' }}
              item
              xs={12}
              md={6}
            >
              <Box className="coupon-header" py={6} px={3}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h4" fontWeight="bold">
                    Coupons
                  </Typography>
                  <img
                    loading="lazy"
                    src={icons.cancel}
                    alt="Coupon Icon"
                    style={{ width: 40, height: 60 }}
                    onClick={handleClose}
                  />
                </Grid>
                <CouponInput />
              </Box>
              <Divider />
              {coupons.map((coupon, index) => (
                <CouponCard
                  key={index}
                  {...coupon}
                  onApplyCoupon={() => {
                    onApplyCoupon(coupon.amount);
                  }} // Pass the function to CouponCard
                  handleClose={handleClose} // Pass handleClose to CouponCard if needed
                />
              ))}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default CouponSection;
