// src/api/shopAPI.ts
import { apiClient } from './apiClient';
import { vendorDetailsResponse } from './types/shop';
import {
  servicesListAPIResponse,
  serviceListReuestIntertface,
} from './types/services';
import {
  appointmentCreateRequestInterface,
  appointmentCreateResponse,
  checkAppointmentAviabilityRequests,
} from './types/appointment';
import {
  proffestionalAPIResponse,
  proffestionalRequestInterface,
} from './types/proffestional';
import {
  appointmentListAPIRequest,
  appointmentListAPIResponse,
} from './types/appointment_list';
import {
  appointmentDetailsRequest,
  appointmentDetailsResponse,
} from './types/appointment_details';
import {
  vendorServicesReqInterface,
  vendorServicesResInterface,
} from './types/vendor_services';
import {
  appointmentAPIResponse,
  appointmentSlotRequestInterface,
} from './types/appointment_slots';
import { ApiResponse } from './types/common';
// Get Vendor Details
export const getVendorDetails = (vendor_detail_id: number) => {
  return apiClient<vendorDetailsResponse>(`/get-vendor-detail`, {
    method: 'POST',
    body: { vendor_detail_id },
  });
};
// Get Service List
export const getServiceList = (requestData: serviceListReuestIntertface) => {
  return apiClient<servicesListAPIResponse>(`/get-appointment-slots`, {
    method: 'POST',
    body: requestData,
  });
};
// Create Appointment
export const createAppointment = (
  requestData: appointmentCreateRequestInterface,
) => {
  return apiClient<appointmentCreateResponse>(`/store-appointment`, {
    method: 'POST',
    body: requestData,
  });
};
// Check Appointment Avaibility
export const checkAppointmentAvaibility = (
  requestData: checkAppointmentAviabilityRequests,
) => {
  return apiClient<appointmentCreateResponse>(
    `/check-appointment-availability`,
    {
      method: 'POST',
      body: requestData,
    },
  );
};
// Get Professional
export const getProfessional = (requestData: proffestionalRequestInterface) => {
  return apiClient<proffestionalAPIResponse>(`/get-professionals`, {
    method: 'POST',
    body: requestData,
  });
};
// Get Appointment List
export const getAppointmentList = (requestData: appointmentListAPIRequest) => {
  return apiClient<appointmentListAPIResponse>(`/get-appointment-list`, {
    method: 'POST',
    body: requestData,
  });
};
// Get Appointment Details
export const getAppointmentDetails = (
  requestData: appointmentDetailsRequest,
) => {
  return apiClient<appointmentDetailsResponse>(`/get-appointment-detail`, {
    method: 'POST',
    body: requestData,
  });
};
// Get Vendor Services
export const getVendorServices = (requestData: vendorServicesReqInterface) => {
  return apiClient<vendorServicesResInterface>(`/get-vendor-services`, {
    method: 'POST',
    body: requestData,
  });
};
// Get Appointment Slots
export const getAppointmentSlots = (
  requestData: appointmentSlotRequestInterface,
) => {
  return apiClient<appointmentAPIResponse>(`/get-appointment-slots`, {
    method: 'POST',
    body: requestData,
  });
};

// Add/Remove Fav Vendor
export const addRemoveFavVendor = (
  vendor_detail_id: number,
) => {
  return apiClient<ApiResponse>(`/add-favorite`, {
    method: 'POST',
    body: { vendor_detail_id: vendor_detail_id },
  });
};


// Get Fav List
export const getFavList = (
) => {
  return apiClient<ApiResponse>(`/get-favorite`, {
    method: 'GET',
  });
};

// Get Appointment List
export const getAppointmentListVendor = (
  customer_id: number,
) => {
  return apiClient<ApiResponse>(`/get-appointment-list`, {
    method: 'POST',
    body: { customer_id: customer_id },
  });
};