import React from 'react';
import { Box, Typography, Divider, ImageListItem } from '@mui/material';
import Switch from '@mui/material/Switch';
import './notification.css';

interface NotificationSettingProps {
  title: string;
  isEnabled: boolean;
}
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const NotificationSetting: React.FC<NotificationSettingProps> = ({
  title,
  isEnabled,
}) => {
  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        mt={5}
        className="notification-setting"
      >
        <Typography variant="body1">{title}</Typography>
        <ImageListItem>
          <Switch {...label} defaultChecked />
        </ImageListItem>
      </Box>
      <Divider className="divider" />
    </>
  );
};

const NotificationsSection: React.FC = () => {
  const notificationSettings = [
    { title: 'Text Message Appointment Notifications', isEnabled: true },
    { title: 'Email Marketing Notifications', isEnabled: false },
    { title: 'Text Message Marketing Notifications', isEnabled: true },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={4}
      mt={8}
      width="100%"
      className="notifications-section"
    >
      <Typography variant="h5">My Notifications</Typography>
      <Typography variant="body1" mt={2} className="notification-description">
        We'll send you updates about your appointments, news and marketing
        offers.
      </Typography>
      {notificationSettings.map((setting, index) => (
        <NotificationSetting key={index} {...setting} />
      ))}
    </Box>
  );
};

export default NotificationsSection;
