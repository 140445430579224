// src/hooks/useVendorDetailsContext.ts
import { useContext } from 'react';
import { VendorDetailsContext } from '../contexts/VendorDetailsContext';

const useVendorDetailsContext = () => {
  const context = useContext(VendorDetailsContext);
  if (!context) {
    throw new Error(
      'useVendorDetailsContext must be used within a DataProvider',
    );
  }
  return context;
};

export default useVendorDetailsContext;
