// API URLs for various endpoints categorized by functionality
export const API_URLS = {
  auth: {
    send_customer_otp: '/send-customer-otp',
    customer_registration: '/customer-registration',
    customer_login: '/customer-login',
    get_profile: '/get-vendor-detail',
  },
  dashboard: {
    customer_dashboard: '/customer-dashboard',
    get_vendor_detail: '/get-vendor-detail',
    get_profile: '/get-vendor-profile',
  },
  appointment: {
    get_vendor_services: '/get-vendor-services',
  },
  business_info: {
    store_business_info: '/store-business-info',
    store_business_address: '/store-business-address',
    store_tool_help_info: '/store-tool-help-info',
  },
  category: {
    store_services: '/store-services',
    get_services: '/get-services',
    remove_service: '/remove-service',
    get_vendor_categories: '/get-vendor-categories',
  },
  business_schedule: {
    store_schedule: '/store-schedule',
    get_schedule: '/get-schedule',
  },
  team: {
    get_team_member: '/get-team-member',
    store_team_member: '/store-team-member',
    store_member_schedule: '/store-member-schedule',
    get_member_schedule: '/get-member-schedule',
  },
  common: {
    common_dropdown: '/get-dropdown-list',
    get_location: '/get-location',
    team_info: '/get-team-information',
    get_schedule_days: '/get-schedule-days',
    get_service_type: '/get-services-type',
    get_service_cat: '/get-categories',
    get_help_information: '/get-help-information',
    get_previous_tools: '/get-previous-tools',
    get_categories: '/get-categories',
    get_dropdown_type: '/get-dropdown-list',
  },
};
