// src/contexts/VendorDetailsContext.tsx
import React, { createContext, useRef, ReactNode } from 'react';
// Interface
import { vendorDetailsResponse } from '../../src/api/types/shop';
// API
import { ShopAPI } from '../../src/hooks/shop';
interface DataContextType {
  getVendorDetailsFunc: (
    vendor_detail_id: number,
  ) => Promise<vendorDetailsResponse>;
}
export const VendorDetailsContext = createContext<DataContextType | undefined>(
  undefined,
);
const VendorDetailsDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { getVendorDetailsAPICall } = ShopAPI();
  // Vendor Details
  const getVendorDetailsFuncRef = useRef(
    async (vendor_detail_id: number): Promise<vendorDetailsResponse> => {
      const result: vendorDetailsResponse =
        await getVendorDetailsAPICall(vendor_detail_id);
      return result;
    },
  );
  return (
    <VendorDetailsContext.Provider
      value={{
        getVendorDetailsFunc: getVendorDetailsFuncRef.current,
      }}
    >
      {children}
    </VendorDetailsContext.Provider>
  );
};
export default VendorDetailsDataProvider;
