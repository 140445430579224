import React, { useEffect, useState, useCallback } from 'react';
import ServiceNavigation from './ServiceNavigation/ServiceNavigation';
import ServiceList from './ServiceList/ServiceList';
import Summary from './Summary/Summary';
import { Container, Box, Grid, Typography, Button } from '@mui/material';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import './ServiceCategories/ServiceCategories.css';
import { ShopAPI } from '../../../src/hooks/shop';
import toast, { Toaster } from 'react-hot-toast';
import { icons } from '../../Assets/icons';
import { useNavigate } from 'react-router-dom';
// Interface
import {
  vendorServicesReqInterface,
  vendorServicesResInterface,
  vendorServicesObject,
  Service,
} from '../../../src/api/types/vendor_services';
import {
  proffestionalRequestInterface,
  professtionalObject,
  proffestionalAPIResponse,
} from '../../../src/api/types/proffestional';
import {
  appointmentSlotRequestInterface,
  appointmentObject,
  appointmentAPIResponse,
} from '../../../src/api/types/appointment_slots';
// Components
import TeamSelection from '../team/TeamSelection/TeamSelection';
import Professional from '../professional/professional';
import Time from '../time/Time';
import ServiceSelection from '../confirm/ServiceSelection/ServiceSelection';
import ReviewConfirm from '../review/ReviewConfirm/ReviewConfirm';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import { VendorDetailData } from '../../../src/api/types/shop';
import {
  appointmentCreateRequestInterface,
  appointmentCreateResponse,
} from '../../../src/api/types/appointment';

// Styles
const Tab = styled(BaseTab)`
  font-family: 'Poppins';
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: black;
    color: white;
  }

  &:focus {
    color: #fff;
    outline: 3px solid black;
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: black;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
  width: 100%;
  font-family: Poppins;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === 'dark' ? 'white' : 'white'};
  border: 1px solid ${theme.palette.mode === 'dark' ? 'white' : 'white'};
  border-radius: 12px;
  `,
);
const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  min-width: 400px;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `,
);
const BookingAppointment: React.FC = () => {
  const navigate = useNavigate();
  // Hooks
  const {
    getVendorServicesAPI,
    getProfessionalAPI,
    getAppoinmentSlotsAPICall,
    checkAppointmentAvaibilityAPICall,
    createAppointmentAPICall,
  } = ShopAPI();
  // States
  const [vendorID, setVendorID] = useState<number>(0);
  const [vendorData, setVendorData] = useState<VendorDetailData>();
  const [vendorServicesData, setVendorServicesData] = useState<
    vendorServicesObject[] | null
  >(null);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [shopCategoryID, setShopCategoryID] = useState('');
  const [shopServiceID, setShopServiceID] = useState('');
  const [serviceIDS, setServiceIDS] = useState<any>([]);
  const [bookPage, setBookPage] = useState<number>(1);
  // Sub States
  const [teamSelectionType, setTeamSelectionType] = useState<number>(1);
  const [vendorServiceMembers, setendorServiceMemebers] = useState<
    professtionalObject[]
  >([]);
  const [selectedTab, setSelectedTab] = useState('');
  const [selectedServiceID, setSelectedServiceID] = useState<any>(null);
  const [appointmentData, setAppointmentData] = useState<
    appointmentObject[] | null
  >([]);
  const [visitedFirstTime, setIsVisitedFirstTime] = useState<boolean>(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  // Date & Time
  const [appointmentDate, setAppointmentDate] = useState('');
  const [appointmentTime, setAppointmentTime] = useState('');
  const [totalHours, setTotalHours] = useState(0);
  const [isSlotAvailable, setIsSlotAvailbel] = useState(true);
  const [customerData, setCustomerData] = useState<any>(null);

  useEffect(() => {
    let customerInfo = localStorage.getItem('customer_details');
    if (customerInfo) {
      customerInfo = JSON.parse(customerInfo);
      setCustomerData(customerInfo);
    }
  }, []);

  useEffect(() => {
    // Set the first tab as selected when vendorServicesData is available
    if (vendorServicesData && vendorServicesData.length > 0) {
      setSelectedTab(`tab_index_${vendorServicesData[0].category_id}`);
    }
  }, [vendorServicesData]);

  // Get Services
  const vendorServices = useCallback(
    async (vendor_detail_id: number) => {
      const requestData: vendorServicesReqInterface = {
        vendor_detail_id: vendor_detail_id,
      };
      const result: vendorServicesResInterface | null =
        await getVendorServicesAPI(requestData);
      if (result === null) {
        toast.error('Failed to fetch vendor services data.');
        return; // Exit the function early if the result is null
      }
      if (result?.status_code === 200) {
        setVendorServicesData(result.data);
      } else {
        toast.error(result?.message);
      }
    },
    [getVendorServicesAPI],
  );
  const servicesAddRemove = (serviceObject: Service, checked: boolean) => {
    if (checked) {
      setSelectedServices([...selectedServices, serviceObject]);
    } else {
      setSelectedServices(
        selectedServices.filter(item => item.id !== serviceObject.id),
      );
    }
  };
  // On Load Functions
  useEffect(() => {
    const shopID = localStorage.getItem('shopID');
    const shopCategoryIDLocal = localStorage.getItem('shop_category_id');
    const shopServiceIDlocal = localStorage.getItem('shop_service_id');

    // const shopID = "35";
    if (shopID) {
      setVendorID(parseInt(shopID));
      vendorServices(parseInt(shopID));
    }
    if (shopCategoryIDLocal && shopServiceIDlocal) {
      setShopCategoryID(shopCategoryIDLocal);
      setShopServiceID(shopServiceIDlocal);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('selected_services', JSON.stringify(selectedServices));
    const idsArray = selectedServices.map(obj => obj.id);
    setServiceIDS(idsArray);
  }, [selectedServices]);
  useEffect(() => {
    if (
      vendorServicesData &&
      vendorServicesData.length > 0 &&
      shopCategoryID &&
      shopServiceID
    ) {
      setSelectedTab(`tab_index_${vendorServicesData[0].category_id}`);
      let selectedCategory = vendorServicesData.filter(row => {
        return row.category_id === shopCategoryID;
      });

      if (selectedCategory && selectedCategory.length > 0) {
        let categoryServices = selectedCategory[0].services;
        if (categoryServices && categoryServices.length > 0) {
          let filterService = categoryServices.filter(row => {
            return row.id === shopServiceID;
          });

          if (filterService && filterService.length > 0) {
            setSelectedServices(filterService);
          }
        }
      }
    }
  }, [vendorServicesData, shopCategoryID, shopServiceID]);
  useEffect(() => {
    const vendorDataLocal = localStorage.getItem('vendor_data');
    if (vendorDataLocal) {
      const vendorDataInfo = JSON.parse(vendorDataLocal);
      setVendorData(vendorDataInfo);
    }
  }, [setVendorData]);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  function handleClose() {
    setopenConfirm(false);
  }

  const navigationFunc = (navigateLInk: string) => {
    navigate(navigateLInk);
  };
  const [openConfirm, setopenConfirm] = useState(false);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  // Page
  const nextPage = () => {
    let nextPageValue = bookPage + 1;
    if (nextPageValue === 3) {
      setIsSlotAvailbel(false);
      getAppoinmentSlotsAPICallFunc();
    }
    if (nextPageValue < 6) {
      setBookPage(nextPageValue);
    }
    if (nextPageValue === 6) {
      createAppointmentAPICallFunc();
    }
  };

  // Team Section
  const selectTeam = (teamType: number) => {
    if (teamType === 2) {
      setTeamSelectionType(2);
    }
  };

  // Get Professtional
  const getProfesstionalFunc = async (serviceObject: Service) => {
    const requestBody: proffestionalRequestInterface = {
      vendor_detail_id: vendorID,
      category_id: parseInt(serviceObject.category_id),
      service_id: parseInt(serviceObject.id),
    };
    setSelectedServiceID(serviceObject.id);
    const getServiceUsers: proffestionalAPIResponse =
      await getProfessionalAPI(requestBody);
    if (getServiceUsers && getServiceUsers.data) {
      setendorServiceMemebers(getServiceUsers.data);
    } else {
      toast.error('Error while fetching professional');
    }
  };

  // Select Professtional
  const selectProfesstionalFunc = (
    professtionalObject: professtionalObject,
  ) => {
    // let findIndex =
    let updateData: any = {
      selectedProfessional:
        professtionalObject.first_name + ' ' + professtionalObject.last_name,
      selectedProfessionalID: professtionalObject.id,
    };
    setSelectedServices(prevItems =>
      prevItems.map(item =>
        item.id === selectedServiceID ? { ...item, ...updateData } : item,
      ),
    );
    setendorServiceMemebers([]);
  };

  const getAppoinmentSlotsAPICallFunc = async () => {
    const req: appointmentSlotRequestInterface = {
      vendor_detail_id: vendorID,
    };
    const result: appointmentAPIResponse = await getAppoinmentSlotsAPICall(req);
    if (result === null) {
      toast.error('Failed to fetch vendor services data.');
      return; // Exit the function early if the result is null
    }
    if (result?.status_code === 200) {
      setAppointmentData(result.data);
    } else {
      toast.error(result?.message);
    }
  };

  const checkAppointmentAvaibilityAPICallFunc = async (
    date: string,
    time: string,
  ) => {
    setAppointmentDate(date);
    setAppointmentTime(time);
    let serviceObjectArray: any = [];
    let totalHours = 0;
    selectedServices.forEach(object => {
      totalHours = parseInt(object.service_hour) + totalHours;
      let reqObject = {
        service_id: object.id,
        member_id: object.selectedProfessionalID,
      };
      serviceObjectArray.push(reqObject);
    });
    setTotalHours(totalHours);
    let checkAppointmentReq = {
      vendor_detail_id: vendorID,
      customer_id: 1,
      services: JSON.stringify(serviceObjectArray),
      appointment_date: date,
      slot_time: time,
    };

    const result: any =
      await checkAppointmentAvaibilityAPICall(checkAppointmentReq);
    if (result === null) {
      toast.error('Failed to check appointment slots.');
      return; // Exit the function early if the result is null
    }
    if (result?.status_code === 200) {
      setIsSlotAvailbel(true);
    } else {
      setIsSlotAvailbel(false);
      toast.error(result?.message);
    }
  };

  const createAppointmentAPICallFunc = async () => {
    let serviceObjectArray: any = [];
    console.log(selectedServices, 'selectedServices');

    selectedServices.forEach(object => {
      let reqObject = {
        service_id: object.id,
        member_id: object.selectedProfessionalID
          ? object.selectedProfessionalID
          : 0,
      };
      serviceObjectArray.push(reqObject);
    });

    const req: appointmentCreateRequestInterface = {
      vendor_detail_id: vendorID,
      customer_id: customerData.customer_id,
      services: JSON.stringify(serviceObjectArray),
      appointment_date: appointmentDate,
      slot_time: appointmentTime,
      is_first_time: visitedFirstTime ? 1 : 2,
      payment_type: 1,
    };
    const result: appointmentCreateResponse =
      await createAppointmentAPICall(req);
    if (result === null) {
      toast.error('Failed to fetch vendor services data.');
      return; // Exit the function early if the result is null
    }
    if (result?.status_code === 200) {
      setopenConfirm(true);
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } else {
      toast.error(result?.message);
    }
  };
  console.log(customerData, 'customerData');
  return (
    <Box
      className="mainBox"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        bgcolor: 'white',
      }}
    >
      <Container
        className="booking"
        component="main"
        sx={{
          mt: 8,
          pl: { xs: 2, md: 6 },
          pr: { xs: 2, md: 6 },
          maxWidth: { xs: '100%', md: '100%' },
        }}
      >
        <Grid
          container
          spacing={5}
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Grid item xs={12} md={12} className="beforeService">
            <ServiceNavigation
              setTeamSelectionType={setTeamSelectionType}
              navigationFunc={navigationFunc}
              setBookPage={setBookPage}
              bookPage={bookPage}
              setIsSlotAvailbel={setIsSlotAvailbel}
            />
          </Grid>
        </Grid>

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openConfirm}
          className="conf"
        >
          <center className="confirmed">
            <div className="image">
              <img src={icons.rightTick} alt="Right Tick"></img>
            </div>
            <h2>Appointment Confirmed!</h2>
            <p>
              Lorem Ipsum is simply dummy text of the<br></br> printing and
              typesetting industry.{' '}
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              sx={{
                textTransform: 'capitalize',
                marginTop: '15px',
                padding: '15px 150px',
              }}
              className="btn btn-primary"
            >
              Close
            </Button>
          </center>
        </BootstrapDialog>

        <Grid
          container
          spacing={5}
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            marginTop: '0px',
            marginBottom: '100px',
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingTop: '0px', paddingLeft: '0px' }}
          >
            <Grid container sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
              {bookPage === 1 && (
                <Grid item xs={12} md={9} sx={{ paddingRight: '5%' }}>
                  <Typography
                    variant="h3"
                    component="h1"
                    sx={{
                      mt: { xs: 10, md: 5 },
                      fontWeight: 'bold',
                      textAlign: 'left',
                      fontSize: '26px',
                      color: 'black',
                      mb: '15px',
                    }}
                  >
                    Select Services
                  </Typography>
                  {vendorServicesData && vendorServicesData?.length > 0 ? (
                    <>
                      <Tabs
                        className="bookingTab"
                        onChange={handleTabChange}
                        defaultValue={`tab_index_${shopCategoryID}`}
                      >
                        <TabsList>
                          {vendorServicesData &&
                            vendorServicesData.length > 0 &&
                            vendorServicesData.map(
                              (row: vendorServicesObject, index: number) => (
                                <Tab
                                  key={index}
                                  value={`tab_index_${row.category_id}`}
                                >
                                  <Typography className="service-item-text">
                                    {row.category_name}
                                  </Typography>
                                </Tab>
                              ),
                            )}
                        </TabsList>

                        {vendorServicesData &&
                          vendorServicesData.length > 0 &&
                          vendorServicesData.map(
                            (row: vendorServicesObject, index: number) => (
                              <TabPanel
                                key={index}
                                value={`tab_index_${row.category_id}`}
                              >
                                <ServiceList
                                  serviceIDS={serviceIDS}
                                  servicesAddRemove={servicesAddRemove}
                                  data={row.services}
                                />
                              </TabPanel>
                            ),
                          )}
                      </Tabs>
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
              )}
              {bookPage === 2 && teamSelectionType === 1 && (
                <TeamSelection selectTeam={selectTeam} />
              )}
              {bookPage === 2 && teamSelectionType === 2 && (
                <Professional
                  vendorServiceMembers={vendorServiceMembers}
                  getProfesstionalFunc={getProfesstionalFunc}
                  data={selectedServices}
                  selectProfesstionalFunc={selectProfesstionalFunc}
                />
              )}
              {bookPage === 3 && (
                <Time
                  checkAppointmentAvaibilityAPICallFunc={
                    checkAppointmentAvaibilityAPICallFunc
                  }
                  appointmentData={appointmentData}
                />
              )}
              {bookPage === 4 && (
                <ServiceSelection
                  setIsVisitedFirstTime={setIsVisitedFirstTime}
                  visitedFirstTime={visitedFirstTime}
                />
              )}

              {bookPage === 5 && (
                <ReviewConfirm setDiscountAmount={setDiscountAmount} />
              )}

              <Grid item xs={12} md={3}>
                <Summary
                  nextPage={nextPage}
                  data={selectedServices}
                  vendorData={vendorData}
                  appointmentDate={appointmentDate}
                  appointmentTime={appointmentTime}
                  totalHours={totalHours}
                  isSlotAvailable={isSlotAvailable}
                  bookPage={bookPage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Toaster />
    </Box>
  );
};
export default BookingAppointment;
