import React from 'react';
import Home from '../../src/Components/home/Home';
import HomeContext from '../contexts/HomeContext';
const HomePage: React.FC = () => {
  return (
    <HomeContext>
      {' '}
      <Home />
    </HomeContext>
  );
};

export default HomePage;
