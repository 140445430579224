import { useState, useRef } from 'react';
import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import './DateSelector.css';
import { icons } from '../../../Assets/icons';
type DateSelectorProps = {
  appointmentData: any;
  setTimeSlots: Function;
  setSelectedDate: Function;
};
const DateSelector: React.FC<DateSelectorProps> = ({
  appointmentData,
  setTimeSlots,
  setSelectedDate,
}) => {
  const [activeDateIndex, setActiveDateIndex] = useState<number | null>(0); // Store active index
  const datesContainerRef = useRef<HTMLDivElement>(null);

  const scrollContainer = (direction: any) => {
    if (datesContainerRef.current) {
      const scrollAmount = direction === 'left' ? -150 : 150;
      if (datesContainerRef.current) {
        datesContainerRef.current.scrollBy({
          left: scrollAmount,
          behavior: 'smooth',
        });
      }
    }
  };
  return (
    <Box
      className="date-selector-container"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      {/*<Button aria-label="Previous week" className="navigation-button" onClick={() => scrollContainer('left')} /> */}
      <Button
        aria-label="Previous week"
        className="navigation-button"
        onClick={() => scrollContainer('left')}
      >
        <img
          loading="lazy"
          src={icons.teamLeftArrow}
          alt="Left arrow"
          className="icon-image"
        />
      </Button>
      <Box
        className="dates-container"
        ref={datesContainerRef}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar
          gap: 2,
        }}
      >
        {appointmentData.map((data: any, index: any) => (
          <Box
            key={index}
            //className={`date-box ${data.isSelected ? 'selected' : ''}`}
            className={`date-box ${activeDateIndex === index ? 'selected' : ''}`} // Apply active class
            onClick={() => {
              setSelectedDate(data.date);
              setTimeSlots(data.slots);
              setActiveDateIndex(index);
            }}
            sx={{
              minWidth: '100px',
              textAlign: 'center',
              cursor: 'pointer',
              padding: '8px',
              borderRadius: '8px',
              '&.selected': {
                backgroundColor: '#1976d2',
                color: '#fff',
              },
            }}
          >
            <Typography variant="h6" className="date-text">
              {data.short_date}
            </Typography>
            <Typography variant="body2" className="day-text">
              {data.short_day_name}
            </Typography>
          </Box>
        ))}
      </Box>

      <Button
        aria-label="Next week"
        className="navigation-button"
        onClick={() => scrollContainer('right')}
      >
        <img
          loading="lazy"
          src={icons.rightArrow}
          alt="Right arrow"
          className="icon-image"
        />
      </Button>
    </Box>
  );
};

export default DateSelector;
