import React from 'react';
import { Box, Typography, Card, CardMedia, Chip, Grid } from '@mui/material';
import './NearbySalon.css';
import { icons } from '../../../Assets/icons';
import { images } from '../../../Assets/images';
import { nearBySalonDetailData } from '../../../../src/api/types/shop';

type NearbySalonProps = {
  data: nearBySalonDetailData;
  onShopClick: any
};

const NearbySalon: React.FC<NearbySalonProps> = ({ data, onShopClick }) => {
  //localStorage.setItem('shopID', data.vendor_detail_id);

  return (
    <Box className="salon-container">
      <Card className="salon-card">
        <CardMedia
          component="img"
          image={data.vendor_media.media_name ? data.vendor_media.media_name : images.noImage}
          alt={`${data.business_name} salon`}
          className="salon-image"
          loading="lazy"
          onClick={() => onShopClick(data.vendor_detail_id)}
        />
        <Box className="salon-info" >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box
                sx={{
                  height: '100%', // Set the height of the box
                  display: 'block',
                }}
              >
                <Box className="salon-details">
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: {
                        xs: '12px', // small screen
                        sm: '10px', // small to medium screen
                        md: '8px', // medium to large screen
                        lg: '12px', // large screen
                        xl: '0.9rem', // extra large screen
                      },
                      fontWeight: 'bold'
                    }}
                    className="salonname"
                  >
                    {data.business_name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: '12px', // small screen
                        sm: '10px', // small to medium screen
                        md: '7px', // medium to large screen
                        lg: '9px', // large screen
                        xl: '13px', // extra large screen
                      },
                    }}
                    className="salon-location"
                  >
                    {data.vendor_address.address_line_1}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                  marginTop: '15px',
                  fontSize: '1.5rem',
                  paddingLeft: '0px',
                }}
              >
                <Chip
                  label={data.categories[0].category_name}
                  sx={{
                    fontSize: {
                      xs: '12px', // small screen
                      sm: '10px', // small to medium screen
                      md: '7px', // medium to large screen
                      lg: '10px', // large screen
                      xl: '13px', // extra large screen
                    },
                  }}
                  className="salon-type"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'left',
                  marginLeft: '15px',
                }}
              >
                <Box className="salon-rating">
                  <Typography className="rating-text">{data.rating}</Typography>
                  <img
                    loading="lazy"
                    src={icons.rating}
                    alt=""
                    className="rating-icon"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'center',
                  marginBottom: '15px',
                  marginRight: '15px',
                }}
              >
                <img
                  loading="lazy"
                  src={icons.recommendedSearch}
                  alt=""
                  className="salon-icon"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

export default NearbySalon;
