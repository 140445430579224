import React, { useEffect, useState } from 'react';
import Header from '../common/Header/Header';
import AppointmentSection from './AppointmentSection/AppointmentSection';
import AppointmentDetails from './AppointmentDetails/AppointmentDetails';
import UserProfile from './UserProfile';
import './AppointmentPage.css';
import { Box, Typography, Grid, Container } from '@mui/material';
import { ShopAPI } from '../../../src/hooks/shop';
import toast from 'react-hot-toast';
const AppointmentPage: React.FC = () => {
  const { getAppointmentListVendorAPICall } = ShopAPI()
  const [appointmentData, setAppointmentData] = useState<any>(null)
  const getAppointmentList = async () => {
    console.log("Came Here");
    console.log(customerData, "customerData");
    if (customerData && customerData?.customer_id) {
      console.log("Came Here");
      const result = await getAppointmentListVendorAPICall(25);
      if (result?.data) {
        console.log("Came HEre FOR UPDATE APPOINTMENT");

        setAppointmentData(result?.data)
      } else {
        toast.error("Error while fetching appointment list")
      }
    }

  }
  const [customerData, setCustomerData] = useState<any>(null);
  useEffect(() => {
    let customerInfo = localStorage.getItem('customer_details');
    if (customerInfo) {
      customerInfo = JSON.parse(customerInfo);
      setCustomerData(customerInfo);
      getAppointmentList();
    }
  }, []);

  useEffect(() => {
    if (customerData) {
      getAppointmentList();
    }
  }, [customerData]);

  console.log(customerData, "customerData");
  console.log(appointmentData, "appointmentData");

  return (
    <Box
      className="appointment-page"
      sx={{ pb: 14, bgcolor: 'white', overflow: 'hidden' }}
    >
      <Container
        component="main"
        sx={{ mt: 3, maxWidth: { md: '1550px', xs: '100%' } }}
      >
        <Grid container spacing={5} direction={{ xs: 'column', md: 'row' }}>
          <Grid item xs={12} md={4} sx={{ mt: { xs: 5, md: 0 } }}>
            <Typography variant="h4" fontWeight="bold" color="textPrimary">
              Appointment
            </Typography>
            {
              appointmentData ? (
                <>
                  <AppointmentSection
                    title="Upcoming"
                    count={appointmentData.upcoming.length}
                    data={appointmentData.upcoming}
                  />
                  <AppointmentSection
                    title="Past"
                    count={appointmentData.past.length}
                    data={appointmentData.past}
                    isPast
                  />
                </>
              ) : ""
            }

          </Grid>
          <Grid item xs={12} md={8} sx={{ mt: { xs: 10, md: 0 } }}>
            <Grid container spacing={5} direction={{ xs: 'column', md: 'row' }}>
              <Grid item xs={12} md={9}>
                <AppointmentDetails
                  title={appointmentData?.latest?.business}
                  date={appointmentData?.latest?.appointment_date}
                  location={appointmentData?.latest?.appointment_date?.address?.address_line_1}
                  service={"N/A"}
                  processTime={appointmentData?.latest?.total_hours}
                  price={appointmentData?.latest?.total_amount}
                  bookingRef={""}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AppointmentPage;
