import React, { useState } from 'react';
import {
  Box,
  Typography,
  Checkbox,
  Button,
  TextField,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Grid,
} from '@mui/material';
import './creatAccount.css';
import BrandLogo from '../login/brandLogo/brandLogo';
import PhoneInput from '../login/phoneInput/phoneInput';
import AuthService from '../../Services/auth.service';
import { useNavigate, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useCookies } from 'react-cookie';
import CommonCookie from '../../Components/CommonCookie';
import { images } from '../../../src/Assets/images';
import { icons } from '../../Assets/icons';
const CreateAccount = () => {
  const authService = AuthService();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);
  // const [passwordError, setPassworderror] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  let [first, setFirst] = useState('');
  const [firstError, setFirstError] = useState('');
  let [last, setLast] = useState('');
  const [lastError, setLastError] = useState('');
  let [number, setNumber] = useState('');
  const [numberError, setNumberError] = useState('');
  let [frmemail, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [terms, setTerms] = useState<string | boolean>('');
  const [termsError, setTermsError] = useState('');

  const handleChange = (event: any) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setTerms('1');
    }
  };

  function addAccountInfo() {
    setFirstError('');
    setLastError('');
    // setPassworderror("");
    setNumberError('');
    setTermsError('');
    setEmailError('');

    let isValid = true;
    first = first.trim();
    last = last.trim();
    number = number.trim();
    frmemail = frmemail.trim();

    if (first === '') {
      setFirstError('Please enter your first Name');
      isValid = false;
    }

    if (last === '') {
      setLastError('Please enter your last Name');
      isValid = false;
    }

    if (frmemail === '') {
      setEmailError('Please enter your email');
      isValid = false;
    }

    if (number.length !== 10) {
      setNumberError('phone number should be 10 digits');
      isValid = false;
    }
    if (isNaN(parseInt(number))) {
      setNumberError('Phone Number should be numeric');
      isValid = false;
    }
    if (number === '') {
      setNumberError('Please enter your Phone Number');
      isValid = false;
    }

    if (!isChecked) {
      setTermsError('Please Accept Terms and Contitions');
      isValid = false;
    }

    if (isValid) {
      registerCustomer();
    }
  }

  const registerCustomer = async () => {
    const fd = new FormData();
    fd.append('country_code', '91');
    fd.append('country_iso', 'in');
    fd.append('mobile', number || '');
    fd.append('email', frmemail || '');
    fd.append('first_name', first || '');
    fd.append('last_name', last || '');
    try {
      const response = await authService().register(fd);
      if (response.data.success) {
        toast.success(response.data.message);
        setCookie(CommonCookie.token, response.data?.data?.token, {
          path: '/',
          secure: true,
          sameSite: 'lax',
        });
        navigate('/profile');
      } else {
        toast.error(response.data.message);
        alert(response.data.message);
      }
    } catch (error: any) {
      toast.error(error?.data?.message || 'Oops, Something went wrong.');
    }
  };
  return (
    <Box className="register login">
      <Grid container spacing={2} className="grid-container">
        <Grid item xs={12} md={6} className="login-section">
          <Box className="form-container">
            <Box className="form-card">
              <form className="form">
                <Box className="topBar">
                  <Link to="/login">
                    <img
                      loading="lazy"
                      src={icons.back}
                      alt=""
                      className="login-form-logo"
                    />
                  </Link>
                  <h4 className="logoMobile">
                    Glam<span>9</span>
                  </h4>
                </Box>
                <Typography variant="h4" component="h1" className="form-title">
                  Create Account
                </Typography>
                <Typography variant="body1" className="form-subtitle">
                  You're almost there! Create your new account by completing
                  these details.
                </Typography>
                <Box className="input-group">
                  <TextField
                    label="First Name"
                    type="text"
                    fullWidth
                    error={!!firstError} // Boolean for error prop
                    helperText={firstError} // Show error message (optional)
                    onChange={e => setFirst(e.target.value)}
                  />
                  <TextField
                    label="Last Name"
                    type="text"
                    fullWidth
                    error={!!lastError} // Boolean for error prop
                    helperText={lastError} // Show error message (optional)
                    onChange={e => setLast(e.target.value)}
                  />
                </Box>
                <TextField
                  label="Email"
                  type="email"
                  fullWidth
                  className="input-field"
                  error={!!emailError} // Boolean for error prop
                  helperText={emailError} // Show error message (optional)
                  onChange={e => setEmail(e.target.value)}
                />

                <PhoneInput
                  email={number}
                  setEmail={setNumber}
                  emailerror={numberError}
                />

                <FormControl error={!!termsError} className="checkbox-field">
                  <FormControlLabel
                    control={
                      <Checkbox
                        //  onChange={e => setTerms(e.target.checked)}
                        onChange={handleChange}
                        checked={isChecked} // Use `checked` instead of `value`
                      />
                    }
                    label="I want to receive information about Glam9 promotions and services, including business tips."
                  />
                  {termsError && (
                    <FormHelperText>{termsError}</FormHelperText> // Display error text if termsError is present
                  )}
                </FormControl>
                <center>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => addAccountInfo()}
                    className="submit-btn"
                  >
                    Continue
                  </Button>
                </center>
              </form>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className="logo-section">
          <Box className="brand-container">
            <img
              loading="lazy"
              src={images.LogoBg}
              alt=""
              className="brand-image"
            />
            <BrandLogo />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default CreateAccount;
