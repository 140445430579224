import React, { useEffect, useState } from 'react';
import Header from '../common/Header/Header';
import Footer from '../common/Footer/Footer';
import ProfileSidebar from './profileSidebar';
import AddressSection from './addressSection';
import PaymentMethodsSection from './PaymentMethodsSection';
import SocialLoginsSection from './SocialLoginsSection';
import NotificationsSection from './NotificationsSection';
import DeleteAccountSection from './DeleteAccountSection';
import QuickLinksSection from './QuickLinksSection';
import { Container, Grid, Box } from '@mui/material';
import './ProfilePage.css';
import { CustomerAPI } from '../../../src/hooks/customer';
import toast from 'react-hot-toast';
const ProfilePage = () => {
  const [customerData, setCustomerData] = useState<any>(null);

  const { getCustomerProfileAPICall } = CustomerAPI();

  useEffect(() => {
    // let customerInfo = localStorage.getItem('customer_details');
    // if (customerInfo) {
    //   customerInfo = JSON.parse(customerInfo);
    //   setCustomerData(customerInfo);
    // }
    getCustomerProfileFunc();
  }, []);

  const getCustomerProfileFunc = async () => {
    const result: any = await getCustomerProfileAPICall();
    if (result === null) {
      toast.error('Failed to get customer profile.');
      return;
    }
    if (result?.status_code === 200) {
      setCustomerData(result.data);
    } else {
      toast.error(result?.message);
    }
  };

  console.log(customerData, 'customerData');

  const userProfile = {
    name: 'Jhon Doe',
    firstName: 'Jhon',
    lastName: 'Doe',
    mobileNumber: '+91-1234567890',
    email: 'jhondoe@email.com',
    dateOfBirth: '01-01-1992',
    gender: 'Male',
  };

  const userAddress =
    'Shakti ETC, Opp. Nayara Petrol Pump, Vandematram, Gota, Ahmedabad, Gujarat-382481';

  return (
    <>
      <Container
        className="profile"
        component="main"
        maxWidth="lg"
        sx={{ mt: { xs: 3, md: 8 }, mb: 4 }}
      >
        <Grid container spacing={3}>
          <Grid className="sidebarContent" item xs={12} md={4}>
            {customerData && <ProfileSidebar customerData={customerData} />}
          </Grid>
          <Grid className="mainContent" item xs={12} md={8}>
            <Box sx={{ mt: { xs: 3, md: 0 } }}>
              {customerData && <AddressSection customerData={customerData} />}

              <PaymentMethodsSection />
              <SocialLoginsSection />
              <NotificationsSection />
              <DeleteAccountSection />
              <QuickLinksSection />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ProfilePage;
