import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import './AppointmentCard.css';

type AppointmentCardProps = {
  title: string;
  date: string;
  price: string;
  itemCount: number;
  isPast?: boolean;
};

const AppointmentCard: React.FC<AppointmentCardProps> = ({
  title,
  date,
  price,
  itemCount,
  isPast = false,
}) => {
  return (
    <Card className={`appointment-card ${isPast ? 'past' : 'future'}`}>
      <Box className="card-container">
        <Box className="image-container">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d1dee10e1dbf5a09bb3f9849b107bc6bec5f7c18e161fb8cf8a747553b959e0d?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13"
            alt={title}
            className="appointment-image"
          />
        </Box>
        <CardContent className="content-container">
          <Typography variant="h6" className="title">
            {title}
          </Typography>
          <Typography variant="body2" className="date">
            {date}
          </Typography>
          <Typography variant="body2" className="price">
            {price}.{itemCount} Item
          </Typography>
          {isPast && (
            <Typography variant="body2" className="book-again">
              Book Again
            </Typography>
          )}
        </CardContent>
      </Box>
    </Card>
  );
};

export default AppointmentCard;
