import React from 'react';
import { Box, InputBase, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: '16px',
    padding: '16px',
    marginTop: '64px',
    maxWidth: '100%',
    fontSize: '16px',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    border: '1px solid rgba(82, 82, 82, 0.5)',
    color: '#525252',
    width: '450px',
    '@media (max-width: 768px)': {
      marginTop: '40px',
      width: '100%',
    },
  },
  flagContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  flagImg: {
    width: '40px',
    height: 'auto',
  },
  downArrow: {
    width: '17px',
    height: 'auto',
  },
  input: {
    flexGrow: 1,
    opacity: 1,
    width: '258px',
  },
  flagIcon: {
    marginRight: 8,
  },
  phoneInput: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
}));

interface PhoneInputProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  emailerror: string;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  email,
  setEmail,
  emailerror,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box className="makeStyles-container-1">
        <Box className={classes.flagContainer}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc518136aec86745ac4efbf6fd06ac3f21561bdfa4a9e4008c57e6d65c9e7d50?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13"
            alt="flag"
            className={classes.flagImg}
          />
          <Typography>+91</Typography>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a383dcd90a35901ccd679fe3d6f6760336b7eb881346ff1a3b7ed6013ea7f40?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13"
            alt="arrow down"
            className={classes.downArrow}
          />
        </Box>
        <InputBase
          type="tel"
          id="phoneNumber"
          placeholder="Your Phone Number"
          className="myClass"
          aria-label="Your Phone Number"
          onChange={e => setEmail(e.target.value)}
        />
      </Box>
      <small className="form-text text-danger">{emailerror}</small>
    </>
  );
};

export default PhoneInput;
