import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Divider,
  TextField,
  Grid,
  Rating,
} from '@mui/material';
import './Sidebar.css';
import { images } from '../../../Assets/images';
import { icons } from '../../../Assets/icons';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import toast from 'react-hot-toast';
import PhoneInput from '../../../Components/login/phoneInput/phoneInput';
import Dialog from '@mui/material/Dialog';
import AppointmentBooking from './AppointmentBooking';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const Sidebar = (props: any) => {
  const [open, setOpen] = React.useState(false);

  let [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  let [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  let [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };
  
  function handleSubmit() {
    let isValid = true;
    setNameError('');
    setEmailError('');
    setPhoneError('');
    name = name.trim();
    email = email.trim();
    phone = phone.trim();
    if (name === '') {
      setNameError('Please enter your name');
      isValid = false;
    }
    if (!validateEmail(email)) {
      setEmailError('Invalid Email! Please enter valid Email');
      isValid = false;
    }
    if (email === '') {
      setEmailError('Please enter your Email');
      isValid = false;
    }
    if (phone.length !== 10) {
      setPhoneError('phone number should be 10 digits');
      isValid = false;
    }
    if (isNaN(parseInt(phone))) {
      setPhoneError('Phone Number should be numeric');
      isValid = false;
    }
    if (phone === '') {
      setPhoneError('Please enter your Phone Number');
      isValid = false;
    }

    if (isValid === true) {
      toast.success('Thanks for your information. we will contact you soon');
    }
  }
    const weekNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const convertToAmPm = (time:any) => {
    const [hour, minute] = time.split(':').map(Number);
    const ampm = hour >= 12 ? 'pm' : 'am';
    const formattedHour = hour % 12 || 12; // Convert hour to 12-hour format
    return `${formattedHour}:${minute.toString().padStart(2, '0')} ${ampm}`;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <aside className="sidebar">
      <Box className="container">
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="appt"
        >
          <AppointmentBooking handleClose={handleClose} />
        </BootstrapDialog>
        <Box className="profile-card">
          <Box className="header" gap={0}>
            {/* <Avatar className="avatar">C<br />&<br />V</Avatar> */}
            {props?.vendorData?.business_logo !== '' ? (
              <img
                className="businessListImage"
                src={props?.vendorData?.business_logo}
                alt="Crown"
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 5,
                  marginTop: 10,
                }} // Set size and margin
              />
            ) : (
              <Box
                className="initialls"
                sx={{
                  width: 40, // Adjust size as needed
                  height: 40,
                  backgroundColor: '#231F20', // Background color for the image
                  borderRadius: '50%', // Makes the box round
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  float: 'left',
                  marginRight: '10px',
                  marginTop: '0px',
                }}
              >
                <Typography
                  variant="h4" // Adjust font size as needed
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '1.35rem',
                  }}
                >
                  {props.convertNameToImage(
                    props?.vendorData?.vendor_detail.data.business_name,
                  )}
                </Typography>
              </Box>
            )}
            {props?.vendorData?.vendor_detail?.data?.salon_type !== '' && (
              <img
                className="businessListImage crown"
                src={images.crown}
                alt="Crown"
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 10,
                  marginBottom: '40px',
                }} // Set size and margin
              />
            )}
            <Box className="details">
              <Typography variant="h3" className="shop-name">
                {props.vendorData?.vendor_detail.data.business_name}
              </Typography>
              <Grid container item xs={12}>
                <Grid item xs={12} className="sidebarRating">
                  <Rating
                    value={
                      props.vendorData?.vendor_detail?.data?.rating_value
                        ? parseInt(
                            props.vendorData?.vendor_detail?.data?.rating_value,
                          )
                        : 0
                    }
                    precision={0.5}
                    readOnly
                    sx={{ marginRight: 2, fontSize: '35px', float: 'left' }}
                  />
                
                  <Typography variant="h2" className="shopTitleRating">
                    {props.vendorData?.vendor_detail.data.rating}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {props.vendorData?.vendor_detail.data.opening_status && (
          <Box className="status">
            <img
              loading="lazy"
              src={icons.time}
              alt=""
              className="status-icon"
            />
            <Typography
              variant="h4"
              className={
                props.vendorData?.vendor_detail.data.opening_status === 'Open'
                  ? 'shopOpened'
                  : 'shopClosed'
              }
              sx={{ marginRight: 2 }}
            >
              {props.vendorData?.vendor_detail.data.opening_status}
            </Typography>
            <Typography
              variant="h2"
              className="shopOpensAt"
              sx={{ marginRight: 2 }}
            >
              {props.vendorData?.vendor_detail.data.opening_time}
            </Typography>
          </Box>
          )}
          <Box className="address">
            <img
              loading="lazy"
              src={icons.location}
              alt=""
              className="address-icon"
            />
            <Typography className="address-text">
              {' '}
              {props.vendorData?.vendor_detail.data.address}
            </Typography>
          </Box>
          
          <Button
            variant="contained"
            onClick={handleClickOpen}
            className="book-button"
          >
            Book Now
          </Button>
          
          <Divider className="divider" />
          <br></br>
          <Typography variant="h3" className="opening-times-title">
            Opening Times
          </Typography>
          <Grid container className="opening-times">



          <Grid container spacing={2}>
      {/* Weekday and status */}
      {weekNames.map((day, index) => {
        // Find matching day in the schedule data
        const matchingDay = props?.vendorData?.schedule?.data?.find(
          (row:any) => row.day_name === day
        );

        // Check if the day is closed
        const isClosed = !matchingDay || matchingDay.is_closed === '1';

        return (
          <Grid item xs={12} key={index} className="day">
            <Grid item xs={6} className="days">
            <Box key={index} className="day">
            <Box display="flex" alignItems="center">
              {/* Status Dot */}
              <Box
                className="status-dot"
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: isClosed ? 'red' : 'green',
                  marginRight: 1,
                }}
              />
              {/* Weekday Name */}

                  

              <Typography
                sx={{
                  color: isClosed ? 'red' : 'inherit',
                }}
              >
                {day}
              </Typography>
            </Box>
            </Box>
            </Grid>
            {/* Operating Hours or Closed Message */}
            <Grid item xs={6} className="days">
            <Box key={index} className="day">
            <Box className="" />
            {isClosed ? (
              
              <Typography sx={{ color: 'red', marginLeft: 2 }}>Closed</Typography>
            ) : (
              <Typography sx={{ marginLeft: 2 }}>
                {convertToAmPm(matchingDay.start_time)} - {convertToAmPm(matchingDay.end_time)}
              </Typography>
            )}
            </Box>
            </Grid>
          </Grid>
        );
      })}
    </Grid>



          {/*   <Grid item xs={6} className="days">
               {props.vendorData?.schedule.data &&
                props.vendorData?.schedule.data.map(
                  (row: any, index: number) => {
                    return (
                      <Box key={index} className="day">
                        <Box className="status-dot" />
                        <Typography>{row.day_name}</Typography>
                      </Box>
                    );
                  },
                )}  

                {weekNames.map((day, index) => (
                    <Box key={index} className="day">
                    <Box className="status-dot" />
                    <Typography>{day}</Typography>
                  </Box>
                  ))} 
                 

                      
            </Grid>
              <Grid item xs={6} className="hours">
                {props.vendorData?.schedule.data &&
                props.vendorData?.schedule.data.map((row: any, index: number) => {
                // Utility function to convert 24-hour time to 12-hour AM/PM format
                const convertToAmPm = (time: string) => {
                const [hour, minute] = time.split(':').map(Number);
                const ampm = hour >= 12 ? 'pm' : 'am';
                const formattedHour = hour % 12 || 12; // Convert hour to 12-hour format
                return `${formattedHour}:${minute.toString().padStart(2, '0')} ${ampm}`;
              };
              return (
                <Box key={index} className="day">
                  <Box className="" />
                  {row.is_closed === '1' ? (
                    <Typography className="closed">Closed</Typography>
                  ) : (
                    <Typography>
                      {convertToAmPm(row.start_time)}
                    {' - '}
                      {convertToAmPm(row.end_time)}
                    </Typography>
                  )}
                </Box>
              );
            })}
          </Grid> */}
          </Grid>
        </Box>
        <Box className="social-accounts">
          <Typography variant="h3">Social Accounts</Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={6} md={2}>
              <Link to="/">
                <img src={icons.shopInstagram} alt="Instagram" />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Link to="/">
                <img src={icons.shopFacebook} alt="Facebook" />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Link to="/">
                <img src={icons.shopTwitter} alt="Twitter" />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Link to="/">
                <img src={icons.shopYoutube} alt="YouTube" />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Link to="/">
                <img src={icons.shopGlobe} alt="Globe" />
              </Link>
            </Grid>
          </Grid>
        </Box>
        <form className="offers-form">
          <Typography
            variant="h3"
            sx={{ textAlign: 'left' }}
            className="form-title"
          >
            Get Offers
          </Typography>
          <Typography
            className="form-subtitle"
            sx={{ textAlign: 'left', marginBottom: 4, fontSize: '18px' }}
          >
            For get latest update
          </Typography>
          <TextField
            id="fullName"
            label="Full Name"
            variant="outlined"
            className="text-field first"
            error={!!nameError} // Boolean for error prop
            helperText={nameError}
            onChange={e => setName(e.target.value)}
          />
          <TextField
            id="emailAddress"
            label="Email Address"
            type="email"
            variant="outlined"
            className="text-field"
            error={!!emailError} // Boolean for error prop
            helperText={emailError}
            onChange={e => setEmail(e.target.value)}
          />
          <PhoneInput
            email={phone}
            setEmail={setPhone}
            emailerror={phoneError}
          />
          {/*  <Grid container spacing={2} className="phone-container">
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Country Code</InputLabel>
                <Select defaultValue="+91">
                  <MenuItem value="+91">+91</MenuItem>
                  
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="phoneNumber"
                label="Your Phone Number"
                variant="outlined"
                className="text-field"
              />
            </Grid>
          </Grid> */}
          <Button
            type="button"
            onClick={handleSubmit}
            variant="contained"
            className="submit-button"
          >
            Book Now
          </Button>
        </form>
      </Box>
    </aside>
  );
};

export default Sidebar;
