import React, { useState } from 'react';
import { Box, Typography, Grid, CardMedia } from '@mui/material';
import './videos.css'; // Import your CSS file
import { AcadamicData } from '../../../../src/api/types/shop';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
interface academicProps {
  data: AcadamicData[];
}
// Modal.setAppElement('#root');
const AcademyVideos: React.FC<academicProps> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<string | null>(null);

  const openModal = (videoId: string) => {
    setCurrentVideo(videoId);
    setIsOpen(true);
  };

  const closeModal = () => {
    setCurrentVideo(null);
    setIsOpen(false);
  };
  return (
    <Box className="academy-videos-container">
    <Box className="divider" />
    <Typography variant="h4" className="title">
      Academy Videos
    </Typography>

    <Grid container spacing={2} className="video-grid">
      {data.map((object: AcadamicData, index: number) => {
        const videoId = object.media_name.replace('https://www.youtube.com/watch?v=', '');
        return (
          <Grid item xs={12} md={3} key={index} className="video-item">
            <Box className="video-wrapper" onClick={() => openModal(videoId)}>
              <CardMedia
                component="iframe"
                width={262}
                height={254}
                src={`https://www.youtube.com/embed/${videoId}?controls=0`} // Disable controls for the thumbnail
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <Box className="video-overlay" />
            </Box>
          </Grid>
        );
      })}
    </Grid>

    {currentVideo && (
      <BootstrapDialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        className="appt"
      >
        <Box>
          <iframe
            width="600"
            height="600"
            src={`https://www.youtube.com/embed/${currentVideo}?autoplay=1`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="1"
          ></iframe>
        </Box>
      </BootstrapDialog>
    )}
  </Box>
  );
};

export default AcademyVideos;
