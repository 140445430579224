import facebook from './facebook.svg';
import google from './google.svg';
import back from './back.svg';
import check from './check.svg';
import switchbox from './switch.svg';
import pwdBar from './pwdBar.svg';
import forgotBar from './forgotBar.svg';
import businessBar from './businessBar.svg';
import hairSaloon from './hairSaloon.svg';
import nailSaloon from './nailSaloon.svg';
import eyeBrow from './eyeBrow.svg';
import barber from './barber.svg';
import skinCare from './skinCare.svg';
import aestheticMedicine from './aestheticMedicine.svg';
import aboutBar from './aboutBar.svg';
import india from './india.svg';
import uk from './UK.svg';
import payVenue from './payVenue.svg';
import us from './US.svg';
import profilePlusAddress from './profilePlusAddress.svg';
import workBar from './workBar.svg';
import addressBar from './addressBar.svg';
import confirmBar from './confirmBar.svg';
import workLocation from './workLocationBar.svg';
import search from './searchAddress.svg';
import teamBar from './teamBar.svg';
import hoursArrow from './hoursArrow.svg';
import serviceBar from './serviceBar.svg';
import deleteService from './deleteService.svg';
import helpBar from './helpBar.svg';
import previousBar from './previousBar.svg';
import calender from './calender.svg';
import dashboard from './dashboard.svg';
import dashboardActive from './dashboardActive.svg';
import salesReport from './salesReport.svg';
import customerList from './customerList.svg';
import whiteCalender from './whiteCalender.svg';
import contactList from './contactList.svg';
import paymentDetails from './paymentDetails.svg';
import teamMembers from './teamMembers.svg';
import settings from './settings.svg';
import notification from './notification.svg';
import help from './help.svg';
import userImage from './userImage.svg';
import heart from './heart.svg';
import service from './services.svg';
import anyDate from './anyDate.svg';
import location from './location.svg';
import rating from './rating.svg';
import recommendedSearch from './recommendedSearch.svg';
import redHeart from './redHeart.svg';
import apple from './apple.svg';
import googledownload from './googleDownload.svg';
import qrCode from './qrCode.svg';
import footerFacebook from './footerFacebook.svg';
import instagram from './instagram.svg';
import linkedIn from './linkedIn.svg';
import twitter from './twitter.svg';
import left from './left.svg';
import right from './right.svg';
import hamburger from './hamburger.svg';
import upload from './upload.svg';
import like from './like.svg';
import shopLocation from './shopLocation.svg';
import rightTick from './rightTick.svg';
import photos from './photos.svg';
import cv from './CandV.svg';
import time from './time.svg';
import shopInstagram from './shopInstagram.svg';
import shopFacebook from './shopFacebook.svg';
import shopTwitter from './shopTwitter.svg';
import shopGlobe from './shopGlobe.svg';
import shopYoutube from './shopYoutube.svg';
import profilePhoto from './profilePhoto.svg';
import settingsUpload from './uploadSvg.svg';
import filter from './filter.svg';
import downArrow from './downArrow.svg';
import serviceHelp from './serviceHelp.svg';
import jackLion from './jackLion.svg';
import laraRay from './laraRay.svg';
import lorenJam from './lorenJem.svg';
import franklinJobs from './franklinJobs.svg';
import micheDom from './michleDom.svg';
import jerry from './jerry.svg';
import edit from './edit.svg';
import teamCalender from './teamCalender.svg';
import calenderEdit from './calenderEdit.svg';
import timer from './timer.svg';
import leftArrow from './leftArrow.svg';
import xlsx from './xls.svg';
import pdf from './pdf.svg';
import imageFile from './imageFile.svg';
import threeDots from './threeDots.svg';
import updownArrows from './upDownArrows.svg';
import downloadPDF from './downloadPdf.svg';
import yellowLocation from './yellowLocation.svg';
import professional from './professional.svg';
import camera from './camera.svg';
import profilePlus from './profilePlus.svg';
import logout from './logout.svg';
import cancel from './cancel.svg';
import blueTick from './blueTick.svg';
import users from './users.svg';
import usersPlus from './usersPlus.svg';
import teamLeftArrow from './teamLeftArrow.svg';
import rightArrow from './rightArrow.svg';
import appointmentHelp from './appointmentHelp.svg';
import upcomingCalender from './upcomingCalender.svg';
import upcomingLocation from './upcomingLocation.svg';
import upcomingManage from './upcomingManage.svg';
import upcomingVenue from './upcomingVenue.svg';
import noAppointment from './noAppointment.svg';
import qrCustomers from './qrCustomers.svg';
import guest from './guest.svg';
import coupen from './coupen.svg';
import coupenArrow from './coupenArrow.svg';
import bookingLeft from './bookingLeft.svg';
import bookingRight from './bookingRight.svg';
import bookingBlueTick from './bookingblueTick.svg';
import apptconf from './ApptConf.svg';
import offer from './offer.svg';
import calenderTeam from './calenderTeam.svg';
import close from './close.svg';
import payonline from './payOnline.svg';

export const icons = {
  facebook,
  google,
  back,
  check,
  coupen,
  offer,
  coupenArrow,
  switchbox,
  calenderTeam,
  close,
  payonline,
  usersPlus,
  profilePlusAddress,
  guest,
  qrCustomers,
  appointmentHelp,
  redHeart,
  upcomingCalender,
  upcomingLocation,
  upcomingManage,
  upcomingVenue,
  noAppointment,
  camera,
  profilePlus,
  professional,
  apptconf,
  pwdBar,
  forgotBar,
  rightTick,
  businessBar,
  payVenue,
  hairSaloon,
  nailSaloon,
  yellowLocation,
  whiteCalender,
  teamLeftArrow,
  rightArrow,
  eyeBrow,
  barber,
  users,
  skinCare,
  aestheticMedicine,
  aboutBar,
  india,
  blueTick,
  uk,
  us,
  heart,
  workBar,
  addressBar,
  search,
  confirmBar,
  workLocation,
  teamBar,
  hoursArrow,
  serviceBar,
  deleteService,
  helpBar,
  previousBar,
  dashboard,
  dashboardActive,
  calender,
  salesReport,
  customerList,
  contactList,
  paymentDetails,
  teamMembers,
  settings,
  notification,
  help,
  userImage,
  service,
  location,
  anyDate,
  rating,
  cancel,
  recommendedSearch,
  apple,
  qrCode,
  googledownload,
  footerFacebook,
  instagram,
  linkedIn,
  twitter,
  left,
  leftArrow,
  right,
  hamburger,
  upload,
  like,
  shopLocation,
  photos,
  cv,
  time,
  shopInstagram,
  shopFacebook,
  shopGlobe,
  shopTwitter,
  shopYoutube,
  profilePhoto,
  settingsUpload,
  filter,
  downArrow,
  serviceHelp,
  jackLion,
  laraRay,
  lorenJam,
  franklinJobs,
  logout,
  micheDom,
  jerry,
  edit,
  calenderEdit,
  teamCalender,
  timer,
  xlsx,
  pdf,
  imageFile,
  threeDots,
  updownArrows,
  downloadPDF,
  bookingLeft,
  bookingRight,
  bookingBlueTick,
};
