import React, { useState } from 'react';
import { Box, Typography, Avatar, Button, Divider, Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText, TextField, } from '@mui/material';
import { images } from '../../Assets/images';
import { icons } from '../../Assets/icons';
import { useCookies } from 'react-cookie';
import Dialog from '@mui/material/Dialog';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled, css } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import CommonCookie from '../CommonCookie';
import './profileSidebar.css';
import { CustomerAPI } from '../../../src/hooks/customer';
import toast from 'react-hot-toast';
interface ProfileSidebarProps {
  customerData: any;
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


const ProfileSidebar: React.FC<ProfileSidebarProps> = ({ customerData }) => {
  const { updateCustomerProfileAPICall } = CustomerAPI()
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);
  function Logout() {
    removeCookie('token', { path: '/' });
    navigate('/');
  }

  const [addressData, setAddressData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickEditOpen = (address: any) => {
    setAddressData(address);
    setOpenEdit(true);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const [customerUpdateData, setCustomerUpdateData] = useState<any>(customerData)

  const updateProfielDataFunc = async () => {
    const result = await updateCustomerProfileAPICall(customerUpdateData);
    if (!result) {
      toast.error("Error while update profile data")
    }
  }

  return (
    <Box className="profile-sidebar">
      <Box className="profile-image-container">

        <BootstrapDialog
          onClose={handleEditClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="appt"
        >
          <Box className="header-box">
            <Typography variant="h4" component="h1">
              Edit Address Details
            </Typography>
            <img
              loading="lazy"
              src={icons.cancel}
              className="address-image"
              alt="Edit Address"
            />
          </Box>

          <Avatar
            alt={`${customerData.first_name} ${' '} ${customerData.last_name}'s profile picture`}
            src={images.bigJohnDoe}
            className="profile-avatar"
          />
          <Link to="#">
            <img alt="" src={icons.camera} className="profile-edit-icon" />
          </Link>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                placeholder="First Name"
                className="input-field"
                fullWidth
                value={customerData.first_name}
                onChange={(event: any) => { setCustomerUpdateData({ ...customerUpdateData, first_name: event.target.value }) }}
                margin="normal"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                placeholder="Last Name"
                className="input-field"
                fullWidth
                margin="normal"
                variant="outlined"
                value={customerData.last_name}
                onChange={(event: any) => { setCustomerUpdateData({ ...customerUpdateData, last_name: event.target.value }) }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                placeholder="Moble Number"
                className="input-field"
                fullWidth
                margin="normal"
                variant="outlined"
                value={customerData.mobile}
                onChange={(event: any) => { setCustomerUpdateData({ ...customerUpdateData, mobile: event.target.value }) }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                placeholder="Email Address"
                className="input-field"
                fullWidth
                margin="normal"
                variant="outlined"
                value={customerData.email}
                onChange={(event: any) => { setCustomerUpdateData({ ...customerUpdateData, email: event.target.value }) }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                variant="outlined"
              >
                <InputLabel>Gender</InputLabel>
                <Select
                  value={customerData.gender_type}
                  onChange={(event: any) => { setCustomerUpdateData({ ...customerUpdateData, gender_type: event.target.value }) }}
                >

                  <MenuItem
                    key={1}
                    value='Male'
                  >
                    Male
                  </MenuItem>
                  <MenuItem
                    key={1}
                    value='FeMale'
                  >
                    Fenake
                  </MenuItem>

                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                placeholder="Date if Birth"
                className="input-field"
                fullWidth
                margin="normal"
                variant="outlined"
                value={customerData.DOB}
                onChange={(event: any) => { setCustomerUpdateData({ ...customerUpdateData, DOB: event.target.value }) }}
              />
            </Grid>
          </Grid>


          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                placeholder="Anniversary Date"
                className="input-field"
                fullWidth
                margin="normal"
                variant="outlined"
                value={customerData.anniversary_date}
                onChange={(event: any) => { setCustomerUpdateData({ ...customerUpdateData, anniversary_date: event.target.value }) }}
              />

              <Grid item xs={6}>

              </Grid>
            </Grid>
          </Grid>

          <Button
            className="continue"
            variant="contained"
            onClick={() => { updateProfielDataFunc() }}
          >
            Update Address
          </Button>

        </BootstrapDialog>

        <Avatar
          alt={`${customerData.first_name} ${' '} ${customerData.last_name}'s profile picture`}
          src={images.bigJohnDoe}
          className="profile-avatar"
        />
        <Link to="#">
          <img alt="" src={icons.camera} className="profile-edit-icon" />
        </Link>
      </Box>
      <Typography variant="h4" className="profile-name">
        {`${customerData.first_name} ${' '} ${customerData.last_name}`}
      </Typography>
      <Button onClick={handleClickOpen} className="edit-info-btn">Edit basic info</Button>
      <Box className="profile-details">
        <Divider />
        <Typography className="profile-label">First Name</Typography>
        <Typography className="profile-value">
          {customerData.first_name}
        </Typography>

        <Typography className="profile-label">Last Name</Typography>
        <Typography className="profile-value">
          {customerData.last_name}
        </Typography>

        <Typography className="profile-label">Mobile Number</Typography>
        <Typography className="profile-value">{customerData.mobile}</Typography>

        <Typography className="profile-label">Email Address</Typography>
        <Typography className="profile-value">{customerData.email}</Typography>

        {customerData.DOB && (
          <>
            <Typography className="profile-label">Date of Birth</Typography>
            <Typography className="profile-value">
              {customerData.DOB}
            </Typography>
          </>
        )}
        {customerData.gender_type && (
          <>
            <Typography className="profile-label">Gender</Typography>
            <Typography className="profile-value">
              {customerData.gender_type}
            </Typography>
          </>
        )}
      </Box>
      {customerData.anniversary_date && (
        <>
          <Typography className="profile-label">Anniversary date</Typography>
          <Typography className="profile-value">{customerData.anniversary_date}</Typography>
        </>
      )}
      <Divider className="divider-bottom" />
      <Button className="logout-btn" onClick={Logout}>
        <span>Log Out</span>
        <img alt="logout" src={icons.logout} className="logout-icon" />
      </Button>
    </Box>
  );
};

export default ProfileSidebar;
