import React from 'react';
import { TextField, FormControl, InputLabel } from '@mui/material';
import './InputField.css';

interface InputFieldProps {
  label: string;
}

const InputField: React.FC<InputFieldProps> = ({ label }) => {
  return (
    <FormControl fullWidth variant="outlined" className="input-field">
      <InputLabel htmlFor={label.toLowerCase().replace(' ', '-')}>
        {label}
      </InputLabel>
      <TextField
        id={label.toLowerCase().replace(' ', '-')}
        placeholder={label}
        variant="outlined"
        InputProps={{
          style: { background: 'transparent' },
        }}
        fullWidth
      />
    </FormControl>
  );
};

export default InputField;
